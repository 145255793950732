export const allianzBuergerNextContactValidation = (
  contact: BaseContactInterface,
  lastCallContact: Nilable<BaseContactInterface>,
  _questionnaire: Nullable<TaskInterface>,
  _events: EventInterface[],
): [boolean, string] => {
  if (contact.id === lastCallContact?.id) {
    return [true, ""];
  }

  const allowedStates = ["Wiedervorlage", "Termin", "nicht interessiert", "nicht erreichbar"];

  if (contact.status && allowedStates.includes(contact.status.name)) {
    return [true, ""];
  }

  const toolTipText =
    "Bitte rufen Sie diesen Kontakt erst an oder setzen Sie ihn auf eine der folgenden Statusmöglichkeiten: „Wiedervorlage“, „Termin“, „nicht interessiert“, „nicht erreichbar“";

  return [false, toolTipText];
};
